import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { SidebarComponent } from './components/layouts/sidebar/sidebar.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard/dashboard.component';
import { AnalyticsComponent } from './components/pages/dashboard/analytics/analytics.component';
import { EcommerceComponent } from './components/pages/dashboard/ecommerce/ecommerce.component';
import { AuthenticationComponent } from './components/pages/authentication/authentication/authentication.component';
import { LoginComponent } from './components/pages/authentication/login/login.component';
import { LoginWithImageComponent } from './components/pages/authentication/login-with-image/login-with-image.component';
import { RegisterComponent } from './components/pages/authentication/register/register.component';
import { RegisterWithImageComponent } from './components/pages/authentication/register-with-image/register-with-image.component';
import { ForgotPasswordComponent } from './components/pages/authentication/forgot-password/forgot-password.component';
import { ForgotPasswordWithImageComponent } from './components/pages/authentication/forgot-password-with-image/forgot-password-with-image.component';
import { ResetPasswordComponent } from './components/pages/authentication/reset-password/reset-password.component';
import { ResetPasswordWithImageComponent } from './components/pages/authentication/reset-password-with-image/reset-password-with-image.component';



import { ReferenceDetailsComponent } from './master/reference-details/reference-details.component';
import { ReferencMasterComponent } from './master/referenc-master/referenc-master.component';

import {FileUploadModule} from 'primeng/fileupload';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {TableModule} from 'primeng/table';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CustomerDetailsComponent } from './master/customer-details/customer-details.component';
import { CustomersComponent } from './master/customers/customers.component';
import { AddBankComponent } from './master/add-bank/add-bank.component';
import { BankDetailsComponent } from './master/bank-details/bank-details.component';
import { AddItemComponent } from './master/add-item/add-item.component';
import { ItemMasterComponent } from './master/item-master/item-master.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { UserMasterComponent } from './master/user-master/user-master.component';
import { AddUserComponent } from './master/add-user/add-user.component';

import { RoleRolesPermissionsComponent } from './role-roles-permissions/role-roles-permissions.component';
import { SearchCustomerComponent } from './search-customer/search-customer.component';
import { ViewOrderDetailsComponent } from './view-order-details/view-order-details.component';
import { NotificatonComponent } from './notificaton/notificaton.component';
import { AddItem1Component } from './master/add-item1/add-item1.component';
import { AddCustomerAutoComponent } from './master/add-customer-auto/add-customer-auto.component';
import { RoleMasterComponent } from './role-master/role-master.component';
import { SettingDashboardComponent } from './setting-dashboard/setting-dashboard.component';
import { CutomerSearchComponent } from './cutomer-search/cutomer-search.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TemplateComponent } from './setting/template/template.component';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';

import { ViewOrderDetails1Component } from './view-order-details1/view-order-details1.component';
import { InvalidLicenceComponent } from './invalid-licence/invalid-licence.component';
import { WebcamModule } from 'ngx-webcam';
import { PrintPageComponent } from './print-page/print-page.component';
import { CalibrationEntryComponent } from './calibration-entry/calibration-entry.component';
import { SharedModule } from './shared/shared.module';
import { SearchInstrumentComponent } from './search-instrument/search-instrument.component';
import { AddRoleComponent } from './add-role/add-role.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForgotNewPasswordComponent } from './forgot-new-password/forgot-new-password.component';
import { AddUserMasterComponent } from './add-user-master/add-user-master.component';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    AnalyticsComponent,
    EcommerceComponent,
    AuthenticationComponent,
    LoginComponent,
    LoginWithImageComponent,
    RegisterComponent,
    RegisterWithImageComponent,
    ForgotPasswordComponent,
    ForgotPasswordWithImageComponent,
    ResetPasswordComponent,
    ResetPasswordWithImageComponent,
    ReferenceDetailsComponent,
    ReferencMasterComponent,
    CustomerDetailsComponent,
    CustomersComponent,
    AddBankComponent,
    BankDetailsComponent,
    AddItemComponent,
    ItemMasterComponent,
    UserMasterComponent,
    AddUserComponent,
    ViewOrderDetailsComponent,
    RoleRolesPermissionsComponent,
    SearchCustomerComponent,
    NotificatonComponent,
    AddItem1Component,
    AddCustomerAutoComponent,
    RoleMasterComponent,
    SettingDashboardComponent,
    CutomerSearchComponent,
    ChangePasswordComponent,
    UserProfileComponent,
    TemplateComponent,
    SalesDashboardComponent,
    ViewOrderDetails1Component,
    InvalidLicenceComponent,
    PrintPageComponent,
    CalibrationEntryComponent,
    SearchInstrumentComponent,
    AddRoleComponent,
    SignUpComponent,
    ForgotNewPasswordComponent,
    AddUserMasterComponent,
   
  ],
  imports: [
    BrowserModule,
    TagModule,

    BrowserAnimationsModule,
    AppRoutingModule,
    DropdownModule,
    RouterModule ,
    FormsModule ,
    TabViewModule,
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule,
    TableModule,
    FileUploadModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-bottom-right'
    }),
    
    ConfirmDialogModule,
    
    WebcamModule
   
  ],

 
  providers: [DatePipe,
    ConfirmationService,
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


 export  interface Order_DB_ {
  Pending: number;
  Delivery: number;
  T_Pending: number;
  T_Orders: number;
  Y_Orders: number;
  Y_Pending: number;
  Y_Delivery: number
}

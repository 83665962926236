import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';

@Component({
  selector: 'app-search-instrument',
  templateUrl: './search-instrument.component.html',
  styleUrls: ['./search-instrument.component.scss']
})
export class SearchInstrumentComponent implements OnInit {

  disp_data:boolean=false;
  constructor(public appservice: AppService,private router: Router) {

    this.add.Master_INS_Code='';
   }
   add:any={};
  ngOnInit(): void {
  }


  load_instrument(item) {
    this.appservice.isEdit =true;
    this.appservice.Edit_Row = item;
    return this.router.navigate(['master/add-instrument']);
  }

  load_Cal_Entry(data)
  {
    

    this.appservice.isEdit=false;
   this.appservice.Ins_Data=data; 
  return this.router.navigate(['calibration-entry']);
  
  }


  get_Master_ID()
{

  
  this.disp_data=false;
  try{
    this.appservice.Selected_Customer = this.appservice.Instrument_Details_Rows.filter(e => e.INS_ID == this.appservice.filter_data)[0];
   
    this.add = this.appservice.Selected_Customer;
    this.disp_data=true;

  }catch(Exception){ console.log(Exception)
  }

 
}

}

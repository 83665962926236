<div class="Qbody">
  <div class="col-md-12 ">
      <div class="content_body">
        <p-tabView>
          <p-tabPanel header="Task ">
            <div *ngIf="Ticket_Details_Rows_Count==0">
              <!-- Display empty content when no records are available -->
              <div class="empty-content">
                <a style="height: 100px; width: 100px;" class="logo">
                  <li style="font-size: 90px; font-weight:900; color :blue ;" class="bx bxs-bell"></li>
              </a><br>
              <h5>No follow-up tasks available</h5>
              <p>It seems like there are no follow-ups to attend to right now.</p>              </div>
            </div>
                <table *ngIf="Ticket_Details_Rows_Count > 0" class="table table-bordered" style="background-color: white; border: 1px solid #dee2e6;">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Ticket No</th>
                      <th class="mhide">Ticket Date</th>
                      <th class="mhide">Assigned By</th>
                      <th class="mhide">Task</th>
                      <th class="mhide">Priority</th>
                      <th class="mhide">Target Date</th>
                      <th class="mhide">Task Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of Ticket_Details_Rows; let i = index" >
                      <td>{{i + 1}}</td>
                      <td><strong>{{data.Ticket_No}}</strong>
                      <span class="mshow ">Date:{{data.Ticket_Date}}</span>
                      <span class="mshow">Assigned By:{{data.Created_by}}</span>
                      <span class="mshow">Task:{{data.Problem_Description}}</span>
                      <span class="mshow">Priority:{{data.Priority}}</span>
                      <span class="mshow">Target Date:{{data.Target_Date}}</span>
                      <span class="mshow">Status:{{data.Task_Status}}</span>

                      </td>
                      <td class="mhide">{{data.Ticket_Date}}</td>
                      <td class="mhide">{{data.Created_by}}</td>
                      <td class="mhide">{{data.Problem_Description}}</td>
                      <td class="mhide"*ngIf="data.Priority === 'High'"><p-tag severity="success" value="{{data.Priority}}"></p-tag></td>
                      <td class="mhide"*ngIf="data.Priority === 'Low'"><p-tag severity="warning" value="{{data.Priority}}"></p-tag></td>
                      <td class="mhide"*ngIf="data.Priority === 'Medium'"><p-tag severity="primary" value="{{data.Priority}}"></p-tag></td>
                      <td class="mhide">
                        {{data.Target_Date}} &nbsp; 
                        <i style="color: red;" *ngIf="data.Reminder_Status" class="pi pi-bell text-3xl"></i>
                      </td>
                      <td class="mhide">{{data.Task_Status}}</td>
                    </tr>
                  </tbody>
                </table>         
          </p-tabPanel>
          <p-tabPanel header="Quotation">
            <div *ngIf="Quotation_Reminder_Rows_Count==0">
              <!-- Display empty content when no records are available -->
              <div class="empty-content">
                <a style="height: 100px; width: 100px;" class="logo">
                  <li style="font-size: 90px; font-weight:900; color :blue ;" class="bx bxs-bell"></li>
              </a><br>
                No follow-up records available.
              </div>
            </div>
            <table *ngIf="Quotation_Reminder_Rows_Count > 0"class="table table-bordered" style="background-color: white; border: 1px solid #dee2e6;border-bottom: 1px solid #dee2e6;">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th class="mhide">Name</th>
                  <th class="mhide">Mobile No</th>
                  <th class="mhide">Followup Details</th>
                  <th class="mhide">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of Quotation_Reminder_Rows; let i = index" >
                  <td>{{i + 1}}</td>
                  <td>{{data.Date}} 
                    <span class="mshow ">Name:{{data.Customer_Name}}</span>
                    <span class="mshow ">Mobile No:{{data.Mobile_No}}</span>
                    <span class="mshow ">Details:{{data.Followup_Details}}</span>
                    <span class="mshow ">Status:{{data.Followup_Status}}</span>

                  </td>
                  <td class="mhide">{{data.Customer_Name}}
                    
                  </td>
                  <td class="mhide">{{data.Mobile_No}}</td>
                  <td class="mhide">{{data.Followup_Details}}</td>
                
                  <td class="mhide"*ngIf="data.Followup_Status === 'Pending'"><p-tag severity="success" value="{{data.Followup_Status}}"></p-tag></td>
                  <td class="mhide"*ngIf="data.Followup_Status === 'In Process'"><p-tag severity="warning" value="{{data.Followup_Status}}"></p-tag></td>
                  <td class="mhide"*ngIf="data.Followup_Status === 'Close'"><p-tag severity="primary" value="{{data.Followup_Status}}"></p-tag></td>
                
                </tr>
              </tbody>
            </table>  
                  </p-tabPanel>
          <p-tabPanel header="Sample Volume">
            <div *ngIf="Sample_Reminder_Rows_Count==0">
              <!-- Display empty content when no records are available -->
              <div class="empty-content">
                <a style="height: 100px; width: 100px;" class="logo">
                  <li style="font-size: 90px; font-weight:900; color :blue ;" class="bx bxs-bell"></li>
              </a><br>
                No follow-up records available.
              </div>
            </div>
         
            <table *ngIf="Sample_Reminder_Rows_Count > 0"class="table table-bordered" style="background-color: white; border: 1px solid #dee2e6;border-bottom: 1px solid #dee2e6;">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th class="mhide">Name</th>
                  <th class="mhide">Mobile No</th>
                  <th class="mhide">Followup Details</th>
                  <th class="mhide">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of Sample_Reminder_Rows; let i = index" >
                  <td>{{i + 1}}</td>
                  <td>{{data.Date}} 
                    <span class="mshow ">Name:{{data.Customer_Name}}</span>
                    <span class="mshow ">Mobile No:{{data.Mobile_No}}</span>
                    <span class="mshow ">Details:{{data.Followup_Details}}</span>
                    <span class="mshow ">Status:{{data.Followup_Status}}</span>

                  </td>
                  <td class="mhide">{{data.Customer_Name}}
                    
                  </td>
                  <td class="mhide">{{data.Mobile_No}}</td>
                  <td class="mhide">{{data.Followup_Details}}</td>
                
                  <td class="mhide"*ngIf="data.Followup_Status === 'Pending'"><p-tag severity="success" value="{{data.Followup_Status}}"></p-tag></td>
                  <td class="mhide"*ngIf="data.Followup_Status === 'In Process'"><p-tag severity="warning" value="{{data.Followup_Status}}"></p-tag></td>
                  <td class="mhide"*ngIf="data.Followup_Status === 'Close'"><p-tag severity="primary" value="{{data.Followup_Status}}"></p-tag></td>
                
                </tr>
              </tbody>
            </table>  
          </p-tabPanel>
      </p-tabView>
 
      <br>
      <br>
      </div>





  </div>





</div>
<ng-container >
  <app-sidebar ></app-sidebar>
  <!-- Start Main Content Wrapper Area -->
  <div  class="main-content d-flex flex-column {{appservice.mani_class}}">
    <app-header ></app-header>
      <router-outlet  ></router-outlet>
     
      <div class="flex-grow-1"></div>
    <!-- <app-footer></app-footer> -->
  </div>
  

  

  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

  
</ng-container>



<div class="row">
  <div class="col-md-12 nopad">
    <div class="card content ">

      <div class=" Head ">



        <div class="card-header  d-flex justify-content-between align-items-center">
          <h3>Change Password</h3>

          <div class="dropdown">

            <a (click)="appservice.back()" style="font-size: 20px;font-weight: 900;cursor: pointer;color:black">
              <b>X</b>
            </a>


          </div>
        </div>

      </div>

      <div class="content_body">



        <form #addForm="ngForm" (ngSubmit)="addData(addForm)" (keydown.enter)="$event.preventDefault()" novalidate>



          <div class="row">

            <div class="col-sm-6">

              <div class="form-table table-responsive">
                <table class="table table-bordered">


                  <tbody>
                   


                    <tr>
                      <td>Current Password<span class="text-danger">*</span></td>
                      <td>
                        <input class="form-control ng-touched" type="password" [(ngModel)]="add.Password" required
                          name="Password" #Password="ngModel">
                        <div *ngIf="addForm.submitted && Password.invalid" class="invalid-feedback d-block">
                          <div class="error">Current Password Should not Empty</div>
                        </div>
                      </td>
                    </tr>


                    <tr>
                      <td>New Password<span class="text-danger">*</span></td>
                      <td> <input class="form-control" type="password" [(ngModel)]="add.New_Password" required
                          name="New_Password" #New_Password="ngModel">
                        <div *ngIf="addForm.submitted && New_Password.invalid" class="invalid-feedback d-block">
                          <div class="error">New Password Should not Empty</div>
                        </div>
                      </td>
                    </tr>


                    <tr>
                      <td>Confirm Password<span class="text-danger">*</span></td>
                      <td><input class="form-control" type="password" [(ngModel)]="add.Confirm_Password" required
                          name="Confirm_Password" #Confirm_Password="ngModel">
                        <div *ngIf="addForm.submitted && Confirm_Password.invalid" class="invalid-feedback d-block">
                          <div class="error">Confirm Password Should not Empty</div>
                        </div>


                        <div *ngIf="addForm.submitted && add.Confirm_Password!=add.New_Password" class="invalid-feedback d-block">
                          <div class="error">Password Not Match!!!</div>
                        </div>

                      </td>
                    </tr>


                  </tbody>

                </table>

                <div class="invoice-btn-box text-right " style="padding-bottom:20px">
            
                 
                  <button class="btn btn-primary rounded-pil  sbtn"  [disabled]="appservice.isload" type="submit"> 
                    <span *ngIf="appservice.isload==true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                    <i   *ngIf="appservice.isload==false" class='bx bx-paper-plane'></i> Save 
                  
                  </button> 

                  &nbsp;&nbsp;&nbsp;

               <button class="btn btn-secondary rounded-pil " (click)="appservice.back()" type="button" >Cancel</button>  &nbsp;&nbsp;
              </div>

              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

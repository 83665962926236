import { Component, OnInit } from "@angular/core";
import { AppService } from 'src/app/app.service';
declare let $: any;

@Component({
    selector: "app-analytics",
    templateUrl: "./analytics.component.html",
    styleUrls: ["./analytics.component.scss"]
})
export class AnalyticsComponent implements OnInit {
    constructor(public appservice:AppService) {
        
        this.appservice.get_Sales_DB();
        this.appservice.get_Order_DB();
        this.appservice.get_Collection_DB();
        this.appservice.get_Pending_Order_DB();
        this.appservice.get_DB_Data();
    }

    ngOnInit() {
        
        
    }
}

<div class="container-fluid">
    <div class="row no-gutters">
      <div class="col">
        <h1> Assistência Nº 1 </h1>
      </div>
    </div>
  
    <div class="row no-gutters">
      <div class="col">
        <img
        src="../../assets/logotipo.svg"
        alt="logotipo"
        class="logotipo">
      </div>
  
      <div class="col">
        <h2>N Reparações</h2>
        <p>91 886 73 76</p>    
        <div class="address">
          <p>geralnreparacoes.com</p>
          <p>Av. Dr. Ribeiro de Magalhães, 1391</p>
          <p>Centro Comercial 123, Loja BD</p>
          <p>4610-108 Felgueiras</p>
        </div>
      </div>
    </div>
  
    <div class="row no-gutters items-group">
      <div class="col-3 label">
        <p>Cliente:</p>
        <p>Contacto:</p>
      </div>
  
      <div class="col-9">
        <p>Manuel Alberto Magalhães Carvalho</p>
        <p>91 000 00 00</p>
      </div>
    </div>
  
    <div class="row no-gutters items-group">
      <div class="col-3 label">
        <p>Equipamento:</p>
        <p>Cor:</p>
        <p>Serial:</p>
        <p>Problema:</p>
        <p>Observações:</p>
      </div>
  
      <div class="col-9">
        <p>Telemóvel > Apple > Iphone 6</p>
        <p>Cinza</p>
        <p>appl2015ewfwejuk23423</p>
        <p>Não arranca</p>
        <p>codigo: 1798 - tem de ficar pronto até dia 12/02/2019</p>
      </div>
    </div>
  
    <div class="row no-gutters items-group">
      <div class="col">
        <p class="label">Condições Gerais:</p>
        <ul class="disclaimer">
          <li>Prazo para levantar 90 dias</li>
          <li>Garantia 90 dias</li>
          <li>Esta assistência só é entregue a quem apresentar este documento</li>
          <li>O preço acresce 10% a cada 30 dias após fim do prazo de levantamento (taxa de conservação)</li>
          <li>Os dados do cliente, acima mencionados, ficam registados na N Reparações apenas para efeitos de comunicação
            em relação às suas assistências e facturação
          </li>
        </ul>
      </div>
    </div>
  
    <div class="row no-gutters">
      <div class="col">
        <p class="disclaimer signature-field">Declaro que li e concordo com o disposto neste documento em 03/02/2019</p>
      </div>
    </div>
  </div>



<div class="row">
  <div class="col-md-12 nopad">

    <div class="col-md-12 nopad mobile_hide">
     
      <div class="w_79" style="position: fixed;z-index: 99; ">
      
        <div class="tabs-header border-bottom">
          <ul>
            <li  *ngFor="let data of appservice.User_Role_Menu"   [className]="appservice.URL_Location==data.Link ? 'active' : ''"  class="" > <a (click)="appservice.load_page(data.Link)">{{data.Menu}}</a></li>
                  
          </ul>
        </div>

        <div class="right-corner">
          
        </div>

      </div>
    </div>

    <div class="col-md-12 nopad">
      <div class="spin_block" *ngIf="appservice.isload">
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <div class="m_2 res_table border" style="padding: 10px;">
       



      
        <div class="row filter-row">

          <div class="col-sm-6 col-md-2 col-xs-6">
              <div class="form-group ">
  
                  <label class="control-label">User Role:</label>
                  <select class="select2-option form-control "  [(ngModel)]="Selected_Rights" name="UM_Rights" #UM_Rights="ngModel" style="width:100%"   aria-hidden="true">
                  
                      <option *ngFor="let summary of Rights_Row" value={{summary.value}}>
                          {{summary.label}}
                      </option>
                  </select>
  
              </div>
          </div>
  
          <div class="col-sm-6 col-md-2 col-xs-6">
              <div class="form-group ">
  
                  <label class="control-label">Module Name:</label>
  
                  <select class="select2-option form-control "  [(ngModel)]="Selected_Module" name="Module_Name" #Module_Name="ngModel" style="width:100%"   aria-hidden="true">
                  
                      <option *ngFor="let summary of Module_Row" value={{summary.value}}>
                          {{summary.label}}
                      </option>
                  </select>
  
            
              </div>
          </div>
  
          <div class="col-sm-6 col-md-2 col-xs-6">
              <label class="control-label">&nbsp;</label>
  
              <a href="javascript:" (click)="display()" class="btn btn-success btn-block" style="height: 35px"> Show </a>
          </div>
  
          <div class="col-sm-6 col-md-2 col-xs-6">
              <label class="control-label">&nbsp;</label>
  
              <button  *ngIf="Row.length>0" (click)="save()"  [disabled]="btndisable" class="btn btn-info btn-block" style="height: 35px"> 
                
                
               
                      <span *ngIf="btndisable==true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <i *ngIf="btndisable==false" class='bx bx-paper-plane'></i>
             
                Save
              </button>
          </div>
  
  
      </div>
  
      <div class="row">
        <div class="col-md-12">
             <input type="checkbox"  [(ngModel)]="Selected"  (change)="select()" > Check All
          </div>
        <div class="col-md-12">

            <div class="entry_table1">

                <div class="header">
                    <div style="width:50px; text-align: center;">#</div>
                    <div *ngFor="let data of rs" style="padding-left: 8px; padding-right: 10px;"  [style.text-align]="data.align" [style.width]="data.width">{{data.Name}}</div>

                </div>



                <div class="body" *ngFor="let rowData of Row;index as i">
                    <div class="row_hover" style="width:50px; text-align: center;">{{i+1}}</div>
                    <div *ngFor="let data of rs" class="in cell row_hover" [style.width]="data.width">

                        <input *ngIf="data.Field=='Check'"  [(ngModel)]="rowData[data.Field]" [style.text-align]="data.align"
                        type="Checkbox" class="in tbtext" style="width: 100%;">
                      


                        <input  *ngIf="!(data.Field=='Check')"  [(ngModel)]="rowData[data.Field]" [style.text-align]="data.align"
                            type="{{data.type}}" class="in tbtext" style="width: 100%;"
                            value="{{rowData[data.Field]}}">


                    </div>


                </div>


            </div>
        </div>
    </div>
      </div>
    </div>
    
  </div>
</div>






import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
declare let $: any;

@Component({
  selector: 'app-notificaton',
  templateUrl: './notificaton.component.html',
  styleUrls: ['./notificaton.component.scss']
})
export class NotificatonComponent implements OnInit {

  constructor(public appservice:AppService) { 
    this.get_Ticket_Details();
    this.get_Quotation_Remainder();
    this.get_Sample_Remainder();

  }

  Ticket_Details_Rows = [];
  Ticket_Details_Rows_Count = 0;
  

  get_Ticket_Details(): void {
    this.Ticket_Details_Rows = [];
    this.Ticket_Details_Rows_Count = 0;
   

    this.appservice.get(`Api/CRM/get_DB_Task_Details?Date=${this.appservice.T_Date}&Emp_ID=${this.appservice.Emp_ID}`)
      .subscribe((res: any) => {
        this.Ticket_Details_Rows = JSON.parse(res).record;
        try {
          this.Ticket_Details_Rows_Count = this.Ticket_Details_Rows.length;
        } catch { }
      });
  }

  public Lead_Details_Rows = [];
  public Lead_Details_Rows_Count = 0;
  
  // Dashboard Data
  public totalLeads: number = 0;
  public openLeads: number = 0;
  public closedLeads: number = 0;
  public inProgressLeads: number = 0;
  get_Lead_Details() {
    this.Lead_Details_Rows = [];
    this.Lead_Details_Rows_Count = 0;
    this.totalLeads = 0;
    this.openLeads = 0;
    this.closedLeads = 0;
    this.inProgressLeads = 0;

    this.appservice.get("Api/CRM/get_Leads_Details?Date=" + this.appservice.T_Date + "&Emp_ID=" + this.appservice.Emp_ID)
      .subscribe((res: any) => {
        this.Lead_Details_Rows = JSON.parse(res).record;
        this.Lead_Details_Rows_Count = this.Lead_Details_Rows.length;
        
        // Calculate the dashboard data based on lead status
        this.Lead_Details_Rows.forEach(lead => {
          this.totalLeads++;
          switch(lead.Lead_Status) {
            case 'Open':
              this.openLeads++;
              break;
            case 'Closed':
              this.closedLeads++;
              break;
            case 'In Progress':
              this.inProgressLeads++;
              break;
            default:
              break;
          }
        });
      });
  }
  Quotation_Reminder_Rows=[];
  Quotation_Reminder_Rows_Count=0;

  get_Quotation_Remainder()
  {
      
      this.Quotation_Reminder_Rows=[];
      this.Quotation_Reminder_Rows_Count=0;

      this.appservice.get("Api/CRM/get_DB_Quotation_Followup?From=" + this.appservice.S_From+"&To="+this.appservice.S_To+"&Followup_Type="+this.appservice.S_Followup_Type+"&Emp_ID="+this.appservice.Emp_ID).subscribe((res: any) => {
      this.Quotation_Reminder_Rows = JSON.parse(res).record;
      try{
      this.Quotation_Reminder_Rows_Count=this.Quotation_Reminder_Rows.length;

      }catch{}
      });
    }
    Sample_Reminder_Rows=[];
    Sample_Reminder_Rows_Count=0;
  
    get_Sample_Remainder()
    {
        
        this.Sample_Reminder_Rows=[];
        this.Sample_Reminder_Rows_Count=0;
  
        this.appservice.get("Api/CRM/get_DB_Sample_Followup?From=" + this.appservice.S_From+"&To="+this.appservice.S_To+"&Followup_Type="+this.appservice.S_Followup_Type+"&Emp_ID="+this.appservice.Emp_ID).subscribe((res: any) => {
        this.Sample_Reminder_Rows = JSON.parse(res).record;
        try{
        this.Sample_Reminder_Rows_Count=this.Sample_Reminder_Rows.length;
  
        }catch{}
        });
      }
  ngOnInit(): void {
  }

}

<div class="row">
    <div class="col-md-12 nopad">
        <a (click)="appservice.back()" class="x" style="position: absolute;
        right: 20px;
        top: 15px;
        z-index: 1;">
            <b>X</b>
        </a>

        <div class="card content ">

            <div class="tabs-header_n border-bottom" style="margin: -15px;    margin-bottom: 0px;">
                <ul>
                    <li [className]="tab==1 ?'active':''"> <a (click)="tab=1">Calibration Entry</a></li>
                    <li [className]="tab==2 ?'active':''"   > <a (click)="tab=2">Documents </a></li>
                    <li [className]="tab==3 ?'active':''"> <a (click)="tab=3">History </a></li>


                </ul>
            </div>




            <div *ngIf="tab==1" class="content_body">




                <form #addForm="ngForm" (ngSubmit)="addData(addForm)" (keydown.enter)="$event.preventDefault()"
                    novalidate>

                    <div class="row">
                        <div class="col-sm-6">

                            <div class="form-table table-responsive">
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr class="hidden">
                                            <td>ID <span class="text-danger">*</span></td>
                                            <td><input class="form-control" type="text" [(ngModel)]="add.ID" name="ID"
                                                    #ID="ngModel"></td>
                                        </tr>

                                        <tr class="hidden">
                                            <td>Cal. No <span class="text-danger">*</span></td>
                                            <td><input class="form-control" type="text" [(ngModel)]="add.Calibration_No"
                                                    name="Calibration_No" #Calibration_No="ngModel"></td>
                                        </tr>

                                        <tr>
                                            <td>Cal Date <span class="text-danger">*</span></td>
                                            <td><input (change)="cal_date()" class="form-control" type="date"
                                                    [(ngModel)]="add.Calibration_Date" name="Calibration_Date"
                                                    #Calibration_Date="ngModel"></td>
                                        </tr>




                                        <tr *ngFor="let data of appservice.get_pos_field('Calibration_Entry','1TL')">
                                            <td>{{data.Name}} <span *ngIf="data.Validate=='True'"
                                                    class="text-danger">*</span></td>

                                            <td>



                                                <div *ngIf="data.Field=='Next_Due'" style="width:100%;display: flex; ">
                                                    <div style="width: 40%;">
                                                        <input class="form-control" [readonly]="true" type="text"
                                                            [(ngModel)]="add.Frequency_Month" name="Frequency_Month"
                                                            #Frequency_Month="ngModel">

                                                    </div>
                                                    <div style="width: 60%;">
                                                        <input class="form-control" [readonly]="true" type="text"
                                                            [(ngModel)]="add.Next_Due" name="Next_Due"
                                                            #Next_Due="ngModel">


                                                    </div>
                                                </div>



                                                <input *ngIf="data.Type!='Select' && data.Field!='Next_Due'"
                                                    class="form-control" [readonly]="data.IsEdit=='False'"
                                                    required="data.Validate=='True'" type="{{data.Type}}"
                                                    [(ngModel)]="add[data.Field]" name="{{data.Field}}"
                                                    #{{data.Field}}="ngModel">

                                                <div *ngIf='addForm.submitted && (data.Validate=="True") && (add[data.Field]=="")'
                                                    class="invalid-feedback d-block">
                                                    <div class="error"> {{data.Name}} Should not entry</div>
                                                </div>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td> Cal. Proc. Ref. No  <span class="text-danger">*</span></td>
                                            <td (click)="open_std(add.Cal_Proc_ID)" >: <a style="color: blue;" > {{add.Pro_Ref_No}}</a></td>
                                        </tr>

                                        

                                        <tr>
                                            <td>Ref. Standard  <span class="text-danger">*</span></td>
                                            <td (click)="open_std(add.Ref_Std_ID)">: <a style="color: blue;" > {{add.Pro_Ref_Standard}}</a></td>
                                        </tr>




                                    </tbody>
                                </table>













                            </div>
                        </div>


                        <div class="col-sm-6">

                            <div class="form-table table-responsive">
                                <table class="table table-bordered">
                                    <tbody>

                                        <tr *ngFor="let data of appservice.get_pos_field('Calibration_Entry','2TR')">
                                            <td>{{data.Name}} <span *ngIf="data.Validate=='True'"
                                                    class="text-danger">*</span></td>

                                            <td>


                                                <select *ngIf="(data.Type=='Select' && data.Field=='Group_ID')"
                                                    class="select2-option form-control" aria-hidden="true"
                                                    required="data.Validate=='True'" [(ngModel)]="add[data.Field]"
                                                    name="{{data.Field}}" #{{data.Field}}="ngModel">
                                                    <option *ngFor="let s of appservice.Ledger_Group" value={{s.ID}}>
                                                        {{s.Ledger_Name}}
                                                    </option>
                                                </select>


                                                <select *ngIf="(data.Type=='Select' && data.Field!='Group_ID')"
                                                    class="select2-option form-control" aria-hidden="true"
                                                    required="data.Validate=='True'" [(ngModel)]="add[data.Field]"
                                                    name="{{data.Field}}" #{{data.Field}}="ngModel">
                                                    <option *ngFor="let row of appservice.get_ref(data.Options)"
                                                        value={{row.label}}>
                                                        {{row[data.Store_Value]}}
                                                    </option>
                                                </select>


                                                <input *ngIf="data.Type!='Select'" class="form-control"
                                                    [readonly]="data.IsEdit=='False'" required="data.Validate=='True'"
                                                    type="{{data.Type}}" [(ngModel)]="add[data.Field]"
                                                    name="{{data.Field}}" #{{data.Field}}="ngModel">

                                                <div *ngIf='addForm.submitted && (data.Validate=="True") && (add[data.Field]=="")'
                                                    class="invalid-feedback d-block">
                                                    <div class="error"> {{data.Name}} Should not entry</div>
                                                </div>
                                            </td>
                                        </tr>


                                        

                                        <tr *ngIf="add.Calibrate_At!='In-House'">
                                            <td>Vendor </td>
                                            <td><input class="form-control" type="text" [(ngModel)]="add.Vendor_Name"
                                                    name="Vendor_Name" #Vendor_Name="ngModel"></td>
                                        </tr>

                                        <tr  *ngIf="add.Calibrate_At!='In-House'">
                                            <td>Cost </td>
                                            <td><input class="form-control" type="text" [(ngModel)]="add.Calibration_Cost"
                                                    name="Calibration_Cost" #Calibration_Cost="ngModel"></td>
                                        </tr>

                                    </tbody>
                                </table>




                            </div>
                        </div>


                        <div class="col-sm-12">
                            <table style="width: 100%; text-align: center;">
                                <tr>



                                    <td style="vertical-align: bottom;">

                                        <br>
                                        Browse & Upload your test Certificate<br>
                                        <p-fileUpload (click)="name_change()" chooseLabel=" Upload Certificate"
                                            mode="basic" auto=true name="myfile[]" url="{{server}}"
                                            (onUpload)="onUpload($event)"></p-fileUpload>

                                        <a style="cursor: pointer; font-weight: 800;color: midnightblue;"
                                            (click)="open_file()"><br>{{file_Name}}</a>

                                    </td>

                                </tr>
                            </table>
                        </div>

                        <div  style="width: 100%;">
                            <div style="width: 100%;">
                             
                            <div class="col-md-12">
                                <span style="font-weight: 800;line-height: 35px;">  Master Instruments </span>

                                <table class="ul table-bordered" style="width: 100%;margin-bottom: 20px;">
                                    <tr style="    background-color: #ebe1f1;
                                    color: #64038c;">
    
                                        <th style="padding-left: 10px;line-height: 35px;">Instrument Code</th>
                                       
                                        <th >Instrument Name</th>
                                        <th > Make</th>
                                        <th >Certificate No</th>
                                        <th >Validity</th>
                                        <th >Days</th>
                                       
    
                                    </tr>
    
    
                                    <tr class="li" *ngFor="let data of appservice.Master_Instrument_Row;let i = index"
                                        style="border-top: 1px solid rgb(0 0 0 / 8%);">
                                        <td  style="padding-left: 10px;line-height: 35px;">{{data.Master_INS_Code}}</td>
    
                                       
                                        <td >{{data.Master_INS_Name}}</td>
                                        <td >{{data.Range}}</td>
                                        <td >{{data.Cer_No}}</td>
                                        <td >{{data.Next_Due}}</td>
                                        <td *ngIf="data.Days>0"  style="color: green;">{{data.Days}}</td>
                                        <td *ngIf="!(data.Days>=0)"  style="background-color: rgb(221, 22, 22);color: white;">{{data.Days}}</td>
                                        
                                        
                                    </tr>
    
    
    
    
    
    
    
    
    
    
                                </table>
                                <div *ngIf="appservice.master_exp==true" class="col-sm-12" style="text-align: center; font-weight: 700;color: rgb(211, 7, 7);" >Master Instrument Validity Expired.. Unable to enter calibration Result...</div>
                            </div>

                            <div class="col-sm-12">

                                <span style="font-weight: 800;line-height: 35px;"> Calibartion Results  <a (click)="open_std(add.File_Name_)"style="color: blue;" >  {{add.Calibration_Standard}}</a> </span> 

                                <table class="ul table-bordered" style="width: 100%;margin-bottom: 20px;">


                                    <tr style="  text-align: center;  background-color: #ebe1f1;
                                    color: #64038c;">

                                        <th rowspan="2" style="padding-left: 10px;line-height: 35px;">S.No</th>

                                        <th style="text-align: center;" rowspan="2">Title</th>
                                      
                                        <th style="text-align: center;" rowspan="2">Characteristics</th>
                                        
                                        <th style="text-align: center;" rowspan="2">Nominal Spec</th>
                                        <th style="text-align: center;"colspan="2">Tolerence </th>
                                        <th style="text-align: center;" [attr.colspan]="Entry">Measured Value </th>
                                        <th *ngIf="Entry!=1" rowspan="2">Average</th>
                                        <th rowspan="2">Deviation</th>
                                        <th rowspan="2">Result</th>

                                    </tr>

                                    <tr style=" text-align: center;   background-color: #ebe1f1;
                                    color: #64038c;">

                                        <th>Min </th>
                                        <th>Max </th>
                                        <th *ngIf="T_==1">{{T_label}}</th>
                                        <th *ngIf="M_==1">{{M_label}}</th>
                                        <th *ngIf="B_==1">{{B_label}}</th>
                                        

                                    </tr>


                                    <tr class="li" *ngFor="let data of Ins_std_Rows;let i = index"
                                        style="border-top: 1px solid rgb(0 0 0 / 8%);">
                                        <td style="padding-left: 10px;line-height: 35px;">{{data.Order_No}}</td>

                                        <td >{{data.Title}}</td>
                                        <td>{{data.Characteristics}}</td>
                                        
                                        <td>{{data.Spec}} {{data.UOM}}</td>
                                        <td>{{data.Spec_tol_Min}}</td>
                                        <td>{{data.Spec_tol_Max}}</td>

                                        <td  *ngIf="T_==1" style="width: 100px;"><input type="number"  *ngIf="appservice.master_exp==false" (keyup)="get_result(data)"
                                            class="form-control form-control-sm"  style="border: none;
                                            background-color: ghostwhite;
                                            font-weight: 800;
                                            font-size: 16px;" [(ngModel)]="data.Ob_value1"
                                            name="T{{data.Ob_value1}}" #T{{data.Ob_value1}}="ngModel"></td>
                                      
                                      <td *ngIf="M_==1" style="width: 100px;"><input type="number"  *ngIf="appservice.master_exp==false"
                                                style="border: none;
                                                background-color: ghostwhite;
                                                font-weight: 800;
                                                font-size: 16px;" 
                                                (keyup)="get_result(data)"
                                                class="form-control form-control-sm" [(ngModel)]="data.Ob_value2"
                                                name="TT{{data.Ob_value1}}" #T{{data.Ob_value1}}="ngModel"></td>
                                                
                                      <td *ngIf="B_==1" style="width: 100px;"><input  *ngIf="appservice.master_exp==false"
                                                    style="border: none;
                                                    background-color: ghostwhite;
                                                    font-weight: 800;
                                                    font-size: 16px;" 
                                                    type="number" (keyup)="get_result(data)"
                                                    class="form-control form-control-sm" [(ngModel)]="data.Ob_value3"
                                                    name="TT{{data.Ob_value1}}" #T{{data.Ob_value1}}="ngModel"></td>

                                                    <td *ngIf="Entry!=1"  >{{data.Observartion}}</td>
                                                    <td>{{data.Diviation}}</td>
                                                  
                                        <td *ngIf="data.ob_Result=='OK'"
                                            style="color: #038f42; font-weight: 900;width: 100px;text-align: center;">
                                            {{data.ob_Result}}</td>
                                        <td *ngIf="data.ob_Result!='OK'"
                                            style="color: #a00707;font-weight: 900;width: 100px; text-align: center;">
                                            {{data.ob_Result}}</td>

                                    </tr>

                                </table>
                                

                            </div>
                        </div>

                        </div> 

<div class="col-md-12">

    <table style="width: 100%;">
    
    <tr>
        <td></td>
        <td style="width: 250px;">
            <div  class="card-body p-4 text-success border-success"  style="text-align: center;"   *ngIf="add.Out_of_Calibration=='No' ">
                <h5  class="card-title font-weight-bold" style="margin: -25px;text-align: center;
                margin-bottom: 0px; line-height:40px;color:white;background-color:green">CALIBRATION OK</h5>
                
                <p  style="text-align: center; line-height: 50px;"   class="card-text">READY TO USE</p>
            </div>
        
        
            <div  class="card-body p-4 text-danger border-danger"  style="text-align: center;"   *ngIf="add.Out_of_Calibration=='Yes'">
                <h5  class="card-title font-weight-bold" style="margin: -25px;text-align: center;
                margin-bottom: 0px; line-height:40px;color:white;background-color:red">OUT OF CALIBRATION </h5>
                
                <p style="text-align: center; line-height: 50px;"  class="card-text">DON'T USE</p>
            </div>
        
        </td>
        <td></td>
    </tr></table>
   

</div>


                        <div class="col-md-12" *ngIf="appservice.master_exp==false" style="padding-top: 30px;">

                            <div class="invoice-btn-box text-center " style="padding-bottom:20px">

                                <button class="btn btn-info rounded-pil" [disabled]="btndisable" type="submit">


                                    <span *ngIf="btndisable==true" class="spinner-border spinner-border-sm"
                                        role="status" aria-hidden="true"></span>
                                    <i *ngIf="btndisable==false" class='bx bx-paper-plane'></i>
                                    Save </button> &nbsp;&nbsp;
                                <button class="btn btn-secondary rounded-pil " type="button"
                                    (click)="appservice.back()">Cancel</button> &nbsp;&nbsp;
                            </div>

                        </div>

                    </div>

                </form>
            </div>

            <div *ngIf="tab==2" class="content_body">

         
              
                <div class="col-md-12 nopad" style="padding: 10px;">
                    <div class="col-md-3 nopad"></div>
                    <div class="col-md-6 nopad" >
                <table class="table table-bordered">
                    <tr>
                        <th>#</th>
                        <th style="width: 50px;"> </th>
                        <th>Document List</th>
                        <th></th>
                    </tr>
    
                    <tr  *ngFor="let data of appservice.INS_Document_List;let i = index">
                        <th >{{(i+1)}}</th>
                        <th  *ngIf="data.ID!=0" (click)="onDelete(data.ID)" style="color: red;width: 50px;" > <li class="bx bx-trash"></li></th>
                        <th  *ngIf="data.ID==0" style="color: red;width: 50px;" ></th>

                        <th  *ngIf="data.ID!=0" (click)="open_file__(data.File_Name)" style="color: blue;" >{{data.Name}}</th>
                        <th  *ngIf="data.ID==0">{{data.Name}}</th>
                        
                        <th *ngIf="data.ID!=0">
                            <li style="
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            background-color: #13bb37;
                            border-radius: 50%;
                            color: #fff;
                            text-align: center;
                            font-size: 22px;
                            " class="bx bx-check-circle"></li>
    
    
                        </th>
    
                        <th *ngIf="data.ID==0">
                            <li style="
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            background-color: #696969;
                            border-radius: 50%;
                            color: #fff;
                            text-align: center;
                            font-size: 22px;
                           " class="bx bx-no-entry"></li>
                        </th>
    
                    </tr>
                </table>
            </div>
            </div>


            </div>

            <div *ngIf="tab==3" class="content_body">

                <div class="col-md-12 nopad">

                    <div class="card-body widget-todo-list" style="border-top: none; 
              margin-right: 0px;
              margin-left: 0px;">



                        <table class="table table-bordered ul" style="width: 100%;margin-bottom: 20px;">
                            <tr style="text-align: center;    line-height: 15px; background-color: ghostwhite;">

                                <th rowspan="2" style="padding-left: 10px;line-height: 35px;">#</th>
                                <th rowspan="2">Cal. Date</th>
                                <th rowspan="2">Next Due</th>
                                <th colspan="3" style="text-align: center;" class="mhide">Calibration Details</th>

                                <th rowspan="2">Verified by</th>
                                <th rowspan="2">Remarks</th>

                                <th rowspan="2" style="text-align: right;padding-right: 10px;">Action</th>

                            </tr>

                            <tr style="text-align: center; line-height: 15px; background-color: ghostwhite;">
                                <th>Certificate No</th>
                                <th>Calibrated by</th>
                                <th>Status</th>
                            </tr>


                            <tr class="li" *ngFor="let data of appservice.Calibration_Entry_Row;let i = index"
                                style="border-top: 1px solid rgb(0 0 0 / 8%);">
                                <td class="mhide" style="padding-left: 10px;line-height: 35px;">{{(i+1)}}</td>

                                <td class="mhide">{{data.Calibration_Date}}</td>
                                <td class="mhide">{{data.Next_Due}}</td>
                                <td class="mhide">{{data.Certificate_No}}</td>
                                <td class="mhide">{{data.Calibrated_by}}</td>
                                <td class="mhide">{{data.Result}}</td>
                                <td class="mhide">{{data.Approved_by}}</td>
                                <td class="mhide">{{data.Remarks}}</td>

                                <td style="padding-right: 10px;">
                                    <div class="todo-item-action" style="position: unset;
                        top: auto;
                        transform: initial;
                        right: auto;
                        text-align: right;">

                                        <a *ngIf="data.Calibrate_At=='Outsourcing'" class="print" href="javascript:void(0)"><i
                                        (click)="get_Calibration(data.Test_File_Name)"style="color: red;" class="bx bxs-file-pdf"></i></a>
                                        <a  *ngIf="data.Approval_Status=='Approved'"class="print" href="javascript:void(0)"><i
                                            (click)="get_Calibration_Report(data.Calibration_No)"style="color: blue;" class="bx bxs-file-pdf"></i></a>


                                            <a  *ngIf="data.Approval_Status!='Approved'"class="delete" href="javascript:void(0)"><i
                                                (click)="edit(data)" class="bx bx-edit-alt"></i></a>


                                        <a class="delete" href="javascript:void(0)"><i
                                                (click)="Delete_Calibration(data.ID)" class="bx bx-trash"></i></a>

                                    </div>
                                </td>
                            </tr>










                        </table>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>

                    </div>
                </div>



            </div>
        </div>
    </div>
</div>
<!-- Start Register Area -->
<div class="register-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="register-form">
                <div class="logo">
                    <a routerLink="/dashboard/analytics"><img src="assets/img/logo.png" alt="image"></a>
                </div>

                <h2>Register</h2>

                <form>
                    <div class="form-group">
                        <input type="text" class="form-control" name="name" placeholder="Name">
                        <span class="label-title"><i class='bx bx-user'></i></span>
                    </div>

                    <div class="form-group">
                        <input type="text" class="form-control" name="email" placeholder="Email">
                        <span class="label-title"><i class='bx bx-envelope'></i></span>
                    </div>

                    <div class="form-group">
                        <input type="password" class="form-control" name="password" placeholder="Password">
                        <span class="label-title"><i class='bx bx-lock'></i></span>
                    </div>

                    <div class="form-group">
                        <div class="terms-conditions">
                            <label class="checkbox-box">I accept <a href="javascript:void(0)">Terms and Conditions</a>
                                <input type="checkbox">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>

                    <button type="submit" class="register-btn">Sign Up</button>

                    <p class="mb-0">Already have account? <a routerLink="/authentication/login">Sign In</a></p>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Register Area -->
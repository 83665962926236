<!-- Breadcrumb Area -->
<div class="breadcrumb-area">
    <h1>Dashboard</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/dashboard/analytics"><i class='bx bx-home-alt'></i></a></li>

        <li class="item">Dashboard</li>

        <li class="item">Analytics</li>
    </ol>
</div>
<!-- End Breadcrumb Area -->

<!-- Start -->
<div class="row">
    <div class="col-lg-3 col-md-6">
        <div class="stats-card-box">
            <a routerLink="/report/pending-orders">
                <div class="icon-box">
                    <i class='bx bx-bar-chart'></i>
                </div>
            </a>
            <span class="sub-title">Pending Orders</span>
            <h3>{{appservice.Pending_Order_DB[0]["Pending"]}} <span class="badge"><i class='bx bx-up-arrow-alt'></i>
                    56.9%</span></h3>

            <div class="progress-list">
                <div class="bar-inner">
                    <div class="bar progress-line" data-width="56.9"></div>
                </div>

                <p>previos order comparision</p>
            </div>
        </div>
    </div>

    <div class="col-lg-3 col-md-6">
        <div class="stats-card-box">
            <div class="icon-box">
                <i class='bx bx-bar-chart-square'></i>
            </div>
            <span class="sub-title">Today Deleivey</span>
            <h3>{{appservice.Pending_Order_DB[0]["Delivery"]}} <span class="badge"><i class='bx bx-up-arrow-alt'></i>
                    32.1%</span></h3>

            <div class="progress-list">
                <div class="bar-inner">
                    <div class="bar progress-line" data-width="32.1"></div>
                </div>

                <p>Delivery compared with Yesterday</p>
            </div>
        </div>
    </div>

    <div class="col-lg-3 col-md-6">
        <div class="stats-card-box">
            <a routerLink="/report/sales-report">
                <div class="icon-box">
                    <i class='bx bx-bar-chart-alt'></i>
                </div>
            </a>
            <span class="sub-title">Sales on {{appservice.Sales_DB[0]["Date"]}}</span>
            <h3>{{appservice.Sales_DB[0]["Amount"]}}
                <span class="badge badge-red">
                    <i class='bx bx-down-arrow-alt'></i>
                    {{appservice.sales_compare}} %</span>
            </h3>

            <div class="progress-list">
                <div class="bar-inner">
                    <div class="bar progress-line" data-width="appservice.sales_compare_bar"></div>
                </div>

                <p>Compared with {{appservice.Sales_DB[1]["Date"]}}</p>
            </div>
        </div>
    </div>

    <div class="col-lg-3 col-md-6">
        <div class="stats-card-box">
            <div class="icon-box">
                <i class='bx bx-paper-plane'></i>
            </div>
            <span class="sub-title">Collection on {{appservice.Collection_DB[0]["Date"]}}</span>
            <h3>{{appservice.Collection_DB[0]["Amount"]}}<span class="badge"><i class='bx bx-up-arrow-alt'></i>
                    26.0%</span></h3>

            <div class="progress-list">
                <div class="bar-inner">
                    <div class="bar progress-line" data-width="26.0"></div>
                </div>

                <p>Compared with {{appservice.Collection_DB[1]["Date"]}}</p>
            </div>
        </div>
    </div>
</div>
<!-- End -->


<!-- Start -->
<div class="row">
    <div class="col-lg-5 col-md-12">

        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>This Month</h3>
            </div>

            <div class="card-body activity-card-box">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="progress-list">
                            <div class="d-flex justify-content-between align-items-center">
                                <span>Total Sales</span>

                                <span>₹{{appservice.DB_Data[0]["Sales"] | number : '1.0-0'}}</span>
                            </div>
                            <div class="bar-inner">
                                <div class="bar progress-line" data-width="80"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="progress-list">
                            <div class="d-flex justify-content-between align-items-center">
                                <span>Total Collection</span>

                                <span>₹{{appservice.DB_Data[0]["Collection"]| number : '1.0-0'}}</span>
                            </div>
                            <div class="bar-inner">
                                <div class="bar progress-line" data-width="98"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="progress-list">
                            <div class="d-flex justify-content-between align-items-center">
                                <span>Total Expense</span>

                                <span>₹{{appservice.DB_Data[0]["Expense"]| number : '1.0-0'}}</span>
                            </div>
                            <div class="bar-inner">
                                <div class="bar progress-line" data-width="95"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="progress-list">
                            <div class="d-flex justify-content-between align-items-center">
                                <span>Outstanding</span>

                                <span>₹{{appservice.DB_Data[0]["Out_standing"] | number : '1.0-0'}}</span>
                            </div>
                            <div class="bar-inner">
                                <div class="bar progress-line" data-width="90"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-30 pt-2">
            <div class="card-body activity-timeline-chart-box">


                <div class="activity-timeline-content">
                    <div class="card-header">
                        <h3>Active Details</h3>
                    </div>

                    <ul>
                        <li>
                            <i class='bx bx-check-double'></i>
                            <span>Total Customers</span>
                            32
                        </li>

                        <li>
                            <i class='bx bx-check-double'></i>
                            <span>Active Customer</span>
                            1 0.5%
                        </li>

                        <li>
                            <i class='bx bx-check-double'></i>
                            <span>Totla Item</span>
                            3,214 0.5%
                        </li>

                        <li>
                            <i class='bx bx-check-double'></i>
                            <span>Active Items</span>
                            2,214 0.9%
                        </li>
                    </ul>
                </div>
            </div>
        </div>


    </div>

    <div class="col-lg-7 col-md-12">


        <div class="row">
            <div class="col-lg-5">
                <div class="card mb-30">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h3>Best Sales</h3>


                    </div>

                    <div class="card-body best-sales-box">
                        <ul>
                            <li class="d-flex align-items-center">
                                <span>S</span>

                                <div class="bar-inner">
                                    <div class="bar progress-line" data-width="95"></div>
                                </div>
                            </li>

                            <li class="d-flex align-items-center">
                                <span>M</span>

                                <div class="bar-inner">
                                    <div class="bar progress-line" data-width="87"></div>
                                </div>
                            </li>

                            <li class="d-flex align-items-center">
                                <span>T</span>

                                <div class="bar-inner">
                                    <div class="bar progress-line" data-width="90"></div>
                                </div>
                            </li>

                            <li class="d-flex align-items-center">
                                <span>W</span>

                                <div class="bar-inner">
                                    <div class="bar progress-line" data-width="45"></div>
                                </div>
                            </li>

                            <li class="d-flex align-items-center">
                                <span>T</span>

                                <div class="bar-inner">
                                    <div class="bar progress-line" data-width="79"></div>
                                </div>
                            </li>

                            <li class="d-flex align-items-center">
                                <span>F</span>

                                <div class="bar-inner">
                                    <div class="bar progress-line" data-width="58"></div>
                                </div>
                            </li>

                            <li class="d-flex align-items-center">
                                <span>S</span>

                                <div class="bar-inner">
                                    <div class="bar progress-line" data-width="77"></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="card mb-30">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h3>Sales </h3>


                    </div>

                    <div class="card-body browser-used-box">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of appservice.Sales_DB">
                                        <td>{{data.Date}}</td>

                                        <td>{{data.Amount}} (<i class='bx bx-up-arrow-alt'></i> {{data.Qty}}Nos)</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End -->
<div style="width: 100%; "   (focusout)="focus_out()"  >  
    <div>  

        <input type="text"    class="in code1 tbtext vin-form" placeholder="{{Placeholder}}"   style="width: 100%; border: 0; border-radius: 0px;"  
        (keydown.enter)="selected_row(row_No)" 
        (keydown.arrowup)="up()"  
        (keydown.arrowdown)="down()"  
        (keydown.escape)="get_view1()"
        (focusout)="focus_out()"
        (mouseleave) ="mouseLeave()"
        value="label_"
        (input)="filter(label_)"
         [(ngModel)]="label_" 
         (ngModelChange)="textChange()"
         [maxlength]="maxLength"  />  

         <span  (click)="open_drop()" style="position: absolute; right: 0px;top: 0px; padding: 10px; padding-right: 20px; cursor: pointer;" class="bx bx-caret-down"></span>

            <div [className]="display_grid==false ? 'hidden' : ''"  style=" z-index: 55; max-height: 270px;position: absolute;width:auto;overflow-x: hidden ;overflow-y: auto; box-shadow: 0 0 3px rgb(0 0 0 / 35%);" >

                
                <table  (mouseenter) ="mouseEnter()"  (mouseleave) ="mouseLeave()" style="margin-top: 2px;  padding: 10px; width: 100%; background-color: white; box-shadow: 0 0 3px rgb(0 0 0 / 35%);">
                      <tr   class="trhover"   style="height: 30px;"  style="cursor: pointer;" *ngFor="let rowData of Row1; let rowIndex=index">
                        
                        
                        <td  (click)="selected_row(rowIndex)"   [className]="rowIndex==row_No ? 'rselected' : ''"   style="text-align: left;  white-space: pre; padding-left: 10px; " >{{rowData.label}}</td>
                      </tr>
                </table>
        

            </div>
    </div>  
</div>  
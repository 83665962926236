import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-calibration-entry',
  templateUrl: './calibration-entry.component.html',
  styleUrls: ['./calibration-entry.component.scss']
})
export class CalibrationEntryComponent implements OnInit {

  add: any = {};
  public btndisable: boolean = false;
  add1: any = {};
  public btndisable1: boolean = false;

  row_of_Used=[];
 
  constructor(public appservice: AppService, private toastr: ToastrService,public datePipe: DatePipe, private http: HttpClient,public confirmationService: ConfirmationService) {


    this.row_of_Used = this.appservice.get_fields_of('Used_Instrument');

    

    if (this.appservice.isEdit) {
      this.add = appservice.Edit_Row;
      this.add.Calibration_Date = appservice.datefromat(this.appservice.Edit_Row.Calibration_Date);
      this.add.Next_Due = appservice.datefromat(this.appservice.Edit_Row.Next_Due);
      
     

    }
    else {

      this.clear();
      this.add.ID = "0";
      this.add.Instrument_ID=this.appservice.Ins_Data.ID;
      this.add.Frequency_Month=this.appservice.Ins_Data.Frequency_Month;
      this.add.INS_ID=this.appservice.Ins_Data.INS_ID;
      this.add.INS_Type=this.appservice.Ins_Data.INS_Type;
      this.add.INS_Name=this.appservice.Ins_Data.INS_Name;
      this.add.Make=this.appservice.Ins_Data.Make;
      this.add.Calibration_Date = this.appservice.T_Date;
      this.add.File_Name_ = this.appservice.Ins_Data.File_Name_;
      this.add.Calibration_Standard = this.appservice.Ins_Data.Calibration_Standard;

      this.add.Calibrated_by=appservice.Current_User.UM_Full_Name;
      
      this.add.Fromat_ = this.appservice.Ins_Data.Fromat_;

      this.add.Cal_Proc_ID=this.appservice.Ins_Data.Cal_Proc_ID;
      this.add.Ref_Std_ID=this.appservice.Ins_Data.Ref_Std_ID;

      try{
      this.add.Pro_Ref_No=this.appservice.Ins_Standard_Row.filter(e=>e.ID==this.add.Cal_Proc_ID)[0]["Ref_No"];
      this.add.Pro_Ref_Standard=this.appservice.Ins_Standard_Row.filter(e=>e.ID==this.add.Ref_Std_ID)[0]["Ref_No"];
      }catch{}

      this.add.Plan_Date= appservice.datefromat(this.appservice.Ins_Data.Next_Calibration_Date);

      this.cal_date();
      this.get_Calibration_No();
      this.appservice.get_Used_Instrument_();

      
      this.appservice.get_Master_Instrument(this.add.Instrument_ID);

      
    }

    this.get_Inspection_Standard(this.add.Instrument_ID);
    this.appservice.get_INS_Submited_Document(this.add.Instrument_ID);
    this.appservice.get_Calibration_Entry(this.add.Instrument_ID);
    
    this.get_format();   
  }


  Entry=3;
  T_label="Top";
  M_label="Middle";
  B_label="Bottom";
  T_=1;
  M_=1;
  B_=1;



  open_file__(data)
  {

    window.open(this.appservice.Server_URL+""+data,"_blank");
  }

  get_format()
  {

    
    if(this.add.Fromat_=="001" || this.add.Fromat_=="003" || this.add.Fromat_=="007")
    {
      this.Entry=3;
      this.T_=1;
      this.M_=1;
      this.B_=1;
      this.T_label="Top";
      this.M_label="Middle";
      this.B_label="Bottom";
    }
    else if(this.add.Fromat_=="005" )
    {
      this.Entry=3;
      this.T_=1;
      this.M_=1;
      this.B_=1;
      this.T_label="M1";
      this.M_label="M2";
      this.B_label="M3";

    }
    else if(this.add.Fromat_=="002" )
    {

      this.T_=1;
      this.M_=1;
      this.B_=0;
      this.Entry=2;
      this.T_label="Top";
      this.M_label="Bottom";
    }
    else if(this.add.Fromat_=="004" )
    {

      this.T_=1;
      this.M_=1;
      this.B_=0;
      this.Entry=2;
      this.T_label="Forwared Direction";
      this.M_label="Reverse Direction";
    }
    else
    {
      this.T_=1;
      this.M_=0;
      this.B_=0;
      this.Entry=1;
      this.T_label="Mean value";
      
    }
    

  }


 


  load_ledg1()
  {
    
    this.appservice.Sales_Temp_Row.INS_Code=this.appservice.filter_data;

    try{
      this.appservice.Selected_Customer = this.appservice.Instrument_Details_Rows.filter(e => e.INS_ID == this.appservice.filter_data)[0];
   
      this.appservice.Sales_Temp_Row.Ins_ID =  this.appservice.Selected_Customer.ID;
      this.appservice.Sales_Temp_Row.INS_Code =  this.appservice.Selected_Customer.INS_ID;
      this.appservice.Sales_Temp_Row.INS_Name =  this.appservice.Selected_Customer.INS_Name;
      this.appservice.Sales_Temp_Row.Make =  this.appservice.Selected_Customer.Make;
      this.appservice.Sales_Temp_Row.Certificate_No =  this.appservice.Selected_Customer.Certificate_No;
      this.appservice.Sales_Temp_Row.Validity =  this.appservice.Selected_Customer.Next_Due;


    }catch(Exception){ console.log(Exception)
    }
  }



  Delete_Calibration(item) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete press Yes?',
      accept: () => {
        this.Delete2(item)
      }
    });
  }
  Delete2(item) {
  
  this.appservice.get("Api/master/delete_Calibration_Entry?ID=" + item).subscribe((res: any) => {
    var index = this.appservice.Calibration_Entry_Row.findIndex(function (items, i) {
      return items.ID === item
    });
    if (index > -1) {
      this.appservice.Calibration_Entry_Row.splice(index, 1);
    }
    this.appservice.Calibration_Entry_Row = this.appservice.Calibration_Entry_Row;
    this.appservice.get_Calibration_Entry(this.add.Instrument_ID);
  });
  }

   

  del_row(item) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete press Yes?',
      accept: () => {
        this.Delete_(item)
      }
    });
  }

  Delete_(item) {

    var index = item;


    if (index > -1) {
      this.appservice.Details_Row.splice(index, 1);
    }
    this.appservice.Details_Row = this.appservice.Details_Row;
    
  }



  add_data(data)
  {
    if(this.appservice.Sales_Temp_Row.INS_Code=="")
    {
      this.toastr.error("Name Should Not Empty", "Error", { timeOut: 3000 });
     return; 
    }

    this.appservice.Details_Row.push(this.appservice.Sales_Temp_Row);
    this.appservice.Sales_Temp_Row={};
    this.appservice.filter_data="";
    
    this.appservice.Sales_Temp_Row.INS_Code="";
    
  }

get_Status()
{
  var x="Ok"
  for(var i=0;i<this.Ins_std_Rows.length;i++)
  {
      if(this.Ins_std_Rows[i]["ob_Result"]!="OK")
      {
        x="Not Ok";
      }
  }


 var Check_Data=true;

  for(var i=0;i<this.Ins_std_Rows.length;i++)
  {
    try
    {
      if(isNaN(this.Ins_std_Rows[i]["Observartion"]))
      {
        Check_Data=false;
      }
    }  catch{

      Check_Data=false;
    }
  }
 

  if(Check_Data==true)
  {
  if(x=="Ok")
  {
  this.add.Out_of_Calibration="No"
  this.add.Result="OK"
  }
  else
  {
    this.add.Out_of_Calibration="Yes"
  this.add.Result="Not OK"
  }
}
else
{
  this.add.Out_of_Calibration=""
  this.add.Result=""
}

}


  get_result(data)
  {

   

    if(data.Result_Type=="Number")
    {


      if(this.Entry==3)
      {
        try {

          data.Observartion = ((parseFloat(data.Ob_value1) + parseFloat(data.Ob_value2) + parseFloat(data.Ob_value3)) / 3). toFixed(3);

          data.Diviation = (parseFloat(data.Observartion) - parseFloat(data.Spec)). toFixed(3);
          if (parseFloat(data.Observartion) >= parseFloat(data.Min_Value) && parseFloat(data.Observartion) <= parseFloat(data.Max_Value)) {

            data.ob_Result = "OK";
          }
          else {
            data.ob_Result = "Not Ok";
          }
        } catch { }
      }
      else  if(this.Entry==2)
      {
        try {

          data.Observartion = ((parseFloat(data.Ob_value1) + parseFloat(data.Ob_value2)) / 2). toFixed(3);

          data.Diviation = (parseFloat(data.Observartion) - parseFloat(data.Spec)). toFixed(3);
          if (parseFloat(data.Observartion) >= parseFloat(data.Min_Value) && parseFloat(data.Observartion) <= parseFloat(data.Max_Value)) {

            data.ob_Result = "OK";
          }
          else {
            data.ob_Result = "Not Ok";
          }
        } catch { }
      }
      else
      {
        try {

          data.Observartion = ((parseFloat(data.Ob_value1) ) / 1). toFixed(3);

          data.Diviation = (parseFloat(data.Observartion) - parseFloat(data.Spec)). toFixed(3);
          if (parseFloat(data.Observartion) >= parseFloat(data.Min_Value) && parseFloat(data.Observartion) <= parseFloat(data.Max_Value)) {

            data.ob_Result = "OK";
          }
          else {
            data.ob_Result = "Not Ok";
          }
        } catch { }
      }
    }
    else if(data.Result_Type=="Text")
      {
        
        if(data.Observartion=data.Text)
      {
        
        data.ob_Result="OK";
      }
      else
      {
        data.ob_Result="Not Ok";
      }

      }
      else
      {
        data.ob_Result="Not Ok";

      }

      this.get_Status();

  }


  Ins_std_Rows=[]
get_Inspection_Standard(ID) {

    
    this.Ins_std_Rows = [];
    this.appservice.get("Api/Master/get_Inspection_Standard?ID="+ID).subscribe((res: any) => {
    this.Ins_std_Rows = JSON.parse(res).record;
  
    });

  }


  uploadedFiles: any[] = [];
  file_Name="";
 certificate_Name="";
  server;
  onUpload(event) {
      for(let file of event.files) {
          this.uploadedFiles.push(file);
          this.file_Name=file.name;
        this.certificate_Name= this.add.Calibration_No.replace("/", "_").replace(/\s/g, "")+"."+file.name.split('.').pop();

        
      }
  
      this.toastr.success("File Uploaded  Success", 'Msg');
      
  }
  get_Calibration(data) 
  {
    
   // window.open(this.appservice.Server_URL+"image/C"+this.appservice.Company+"/Test_Certificate/"+this.certificate_Name,"_blank")
    window.open(this.appservice.Server_URL+"image/C"+this.appservice.Company+"/Test_Certificate/"+data,"_blank")
  
  
  }
  get_Calibration_Report(data) 
{
  window.open(this.appservice.Server_URL+"report/Print_Cal_Certificate?Cal_No="+data+"&Company="+this.appservice.Company, "_blank");


}

  open_file()
  {
    window.open(this.appservice.Server_URL+"image/C"+this.appservice.Company+"/Test_Certificate/"+this.certificate_Name,"_blank")
  }

  open_std(data)
  {
    var file=this.appservice.Ins_Standard_Row.filter(e=>e.ID==data)[0]["Ins_File_Name"];
    window.open(this.appservice.Server_URL+file,"_blank")
  }




  edit(data)
  {
    this.tab=1;
    this.add=data;
    this.add.Calibration_Date = this.appservice.datefromat(this.add.Calibration_Date);

    this.appservice.get("Api/Master/get_Calibration_details?Calibration_No="+this.add.Calibration_No).subscribe((res: any) => {
      this.Ins_std_Rows = JSON.parse(res).record;
    
      });
  
  
  }
  
  cal_date()
  {
    
    var d = new Date(this.add.Calibration_Date);
    d.setMonth(d.getMonth() +    parseInt(this.add.Frequency_Month));
    d.setDate(d.getDate() - 1);
    this.add.Next_Due = this.datePipe.transform(d, "dd-MMM-yyyy");

  }


 
  clear() {

    this.appservice.get_fields_of('Calibration_Entry').forEach((activity) => {
      if (activity.Default_Value == "T_Date") {
        this.add[activity.Field] = this.appservice.T_Date;
      }
      else {
        this.add[activity.Field] = activity.Default_Value;
      }
    });
  }
  Valid;
 
  get_Calibration_No(){
    this.appservice.getc("Api/Invoice/get_Calibration_No").subscribe((res: any) => {
     
    this.add.Calibration_No = res;
    this.add.Certificate_No = res;
    this.name_change();
    });
  }
 
 
  name_change()
  {
    this.server=this.appservice.Server_URL+"Api/Master/UploadCalibration_Document?ID="+this.add.ID+"&Company="+this.appservice.Company+"&Name="+this.add.Calibration_No;

  }
  onDelete(item) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete press Yes?',
      accept: () => {
        this.Delete(item)
      }
    });
}
Delete(item) {
  
  this.appservice.get("Api/master/delete_Used_Instrument?ID=" + item).subscribe((res: any) => {
    var index = this.appservice.Used_Instrument_Rows.findIndex(function (items, i) {
      return items.ID === item
    });
    if (index > -1) {
      this.appservice.Used_Instrument_Rows.splice(index, 1);
    }
    this.appservice.Used_Instrument_Rows = this.appservice.Used_Instrument_Rows;
    this.appservice.get_Used_Instrument_();
  });
}
  tab=1
  addData(f) {




    this.Valid = true;
    this.appservice.get_fields_of('Calibration_Entry').filter(e => String(e.Validate).toLocaleLowerCase() == "true").forEach((data) => {

      if (this.add[data.Field] == "") {
        this.Valid = false;
        return;
      }

    });

    this.add.Company = this.appservice.Company;
    this.add.Company_ID = this.appservice.Company;
    this.add.Created_by = this.appservice.CREATED_BY;
    this.add.Test_File_Name = this.certificate_Name;

    this.add.Master_tbl = this.appservice.Master_Instrument_Row;
    this.add.Result_tbl = this.Ins_std_Rows;
    this.add.Table_Name = "Calibration_Entry";
    if (this.Valid) {
      this.btndisable = true;

      this.http.post(this.appservice.Server_URL + 'api/Master/Post_Calibration_Entry', this.add, { headers: this.appservice.headers })
        .subscribe(
          (val: string) => {
            this.btndisable = false;
            

            if (val == "True") {
              this.toastr.success("Details Salved Success", 'Msg');
              this.appservice.isEdit = false;
              this.appservice.get_Instrument_Details();
              this.appservice.back();
            }
            else {
              this.toastr.error(val, "Error", { timeOut: 3000 });
            }
          },
          response => {
            this.toastr.error('Error ', response, {
              timeOut: 3000
            });

          });

    }
  }
  add_Used_Instrument(f) {




    this.Valid = true;
    this.appservice.get_fields_of('Used_Instrument').filter(e => String(e.Validate).toLocaleLowerCase() == "true").forEach((data) => {

      if (this.add1[data.Field] == "") {
        this.Valid = false;
        return;
      }

    });

    this.add1.Company = this.appservice.Company;
    this.add1.Company_ID = this.appservice.Company;
    this.add1.Created_by = this.appservice.CREATED_BY;

    this.add1.Table_Name = "Used_Instrument";
    if (this.Valid) {
      this.btndisable1 = true;

      this.http.post(this.appservice.Server_URL + 'api/Master/Post_Used_Instrument', this.add1, { headers: this.appservice.headers })
        .subscribe(
          (val: string) => {
            this.btndisable1 = false;
            

            if (val == "True") {
              this.toastr.success("Details Salved Success", 'Msg');
              this.appservice.isEdit = false;
              this.appservice.get_Used_Instrument_();

            }
            else {
              this.toastr.error(val, "Error", { timeOut: 3000 });
            }
          },
          response => {
            this.toastr.error('Error ', response, {
              timeOut: 3000
            });

          });              this.appservice.get_Used_Instrument_();


    }
  }
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.appservice.move=true;
  }


}

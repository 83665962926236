import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  data :any={
    'UM_Full_Name':''
  }
    constructor(public router:Router,public appservice:AppService) {
     
      this.data=JSON.parse(localStorage.getItem('User_Data'));
   
    

     }
     addReset() {
      this.appservice.isadd = "0";
      return this.router.navigate(['/change-password']);
    }
    
     lenth:number=0;

     logout()
     {
      localStorage.clear();
   
      this.appservice.Company="";
      this.router.navigate(['/'], { replaceUrl: true });
     }
     get_Home()
     {
      return this.router.navigate([this.appservice.Lab_Home_Page]);

     }

     get_Search()
     {
      return this.router.navigate(['/transaction/search-patient']);

     }
     Bill()
     {
      return this.router.navigate(['/transaction/patient-entry']);
     }

     
     Result()
     {
      return this.router.navigate(['/transaction/test-entry-details']);
     }
     
     search()
     {
      return this.router.navigate(['/transaction/search-patient']);

     }


    ngOnInit() {
    }

   
}

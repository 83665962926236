import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})


export class CustomerDetailsComponent implements OnInit {
  ngOnInit() : void{
  }
  Rows = [];
  cols: any[];
  constructor(public confirmationService:ConfirmationService,private toastr: ToastrService, public appservice: AppService, private http: HttpClient,  private router: Router, private route: ActivatedRoute) {

    
  }
  addReset() {
    this.appservice.isadd = "0";
    return this.router.navigate(['/add-customer']);
  }
  onEdit(item) {
    this.appservice.isadd = "1";
    this.appservice.Edit_Row = item;
    return this.router.navigate(['/add-customer']);
  }
  onDelete(item) {
    this.confirmationService.confirm({
        message: 'Are you sure that you want to delete press Yes?',
        accept: () => {
          this.Delete(item) 
        }
    });
  }
  
  Delete(item) { 
      this.appservice.get("Api/master/delete_Ledger_Master?ID=" + item).subscribe((res: any) => {
      var index = this.appservice.Ledger_Master_Rows.findIndex(function (items, i) {
        return items.ID === item
      });
      if (index > -1) {
        this.appservice.Ledger_Master_Rows.splice(index, 1);
      }
      this.appservice.Ledger_Master_Rows = this.appservice.Ledger_Master_Rows;
      this.appservice.get_Ledger_Master();
    });
  }
 
  filter(data)
  {
this.appservice

  }

public count=0;
  handleFilter(e,filteredRecordCount){

    this.count= e.filteredValue.length;

 }

  export_excel(data)
  {
 
   this.appservice.Excel_Data.Header=this.appservice.Ledger_Master_Export;
   this.appservice.Excel_Data.items=this.appservice.Ledger_Master_Rows
   this.appservice.export_excel();
 
  }


  export_pdf(data)
 {

  this.appservice.Excel_Data.Header=this.appservice.Ledger_Master_Export;
  this.appservice.Excel_Data.items=this.appservice.Ledger_Master_Rows
  this.appservice.export_pdf();
  }


}

import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';

declare let $: any;
@Component({
  selector: 'app-share-report',
  templateUrl: './share-report.component.html',
  styleUrls: ['./share-report.component.scss']
})
export class ShareReportComponent implements OnInit {
  public btndisable: boolean = false;
  public btndisable1: boolean = false;

  constructor(public confirmationService: ConfirmationService, private toastr: ToastrService, public appservice: AppService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {

   
  }


    SMS_Send=[];
    Open_Customer_Module(data)
    {
      this.appservice.Share_Data = {};
      this.appservice.get("Api/Master/get_Share_Data?Bill_No="+data.Bill_No).subscribe((res: any) => {
        this.appservice.Share_Data=JSON.parse(res).record[0];
                $('#Customer_Module').modal('show');
      });
  
  
  
      
    }

    Send_Report_SMS()
    {
    var bill=this.appservice.Share_Data.Bill_No;
    var Ph=this.appservice.Share_Data.Phone_Number;
    var s=this.appservice.Share_Data.Sample_Category_;

    if (s.toLowerCase() == "humans")
    {
        s = "Molecular diagnostics";

    }
  
    else if (s.toLowerCase() == "research")
    {
        s = "Genomics research";
    }
    else if (s.toLowerCase() == "testing")
    {
        s = "Genomics-based testing";
    }
    else if (s.toLowerCase() == "veterinary")
    {

        s = "Molecular diagnostics";
    }
    else if (s.toLowerCase() == "bioinformatics")
    {
        s = "In-silico research";
    }
    else
    {
        s = "";
    }
     var s_="Dear Sir/Madam, %0a  Greetings from Elango Genetics Pvt Ltd! Thank you for choosing Elango Genetics Pvt Ltd as your preferred " + s + " partner.%0a  Please find Report link bellow %0a "+this.appservice.Mail_Server_URL+"/Report/Data?Bill_No="+bill;
     window.open("https://wa.me/+91"+Ph+"/?text="+s_);
    }
    New_Whatsapp()
    {
    var  bill=this.appservice.Share_Data.Bill_No;
    var Ph=this.appservice.Share_Data.Phone_Number;
    var s=this.appservice.Share_Data.Sample_Category_;

    if (s.toLowerCase() == "humans")
    {
        s = "Molecular diagnostics";

    }
  
    else if (s.toLowerCase() == "research")
    {
        s = "Genomics research";
    }
    else if (s.toLowerCase() == "testing")
    {
        s = "Genomics-based testing";
    }
    else if (s.toLowerCase() == "veterinary")
    {

        s = "Molecular diagnostics";
    }
    else if (s.toLowerCase() == "bioinformatics")
    {
        s = "In-silico research";
    }
    else
    {
        s = "";
    }
     var s_="Dear Sir/Madam, %0a  Greetings from Elango Genetics Pvt Ltd! Thank you for choosing Elango Genetics Pvt Ltd as your preferred " + s + " partner.%0a  Please find Report link bellow %0a "+this.appservice.Mail_Server_URL+"/Report/Data?Bill_No="+bill;
     this.appservice.get("Api/Invoice/New_Whatsapp_Send?Bill_No="+ bill +"&Phone_No="+Ph+"&Sample_Category="+s+"&Body="+s_).subscribe((res: any) => {
      this.toastr.success("Whatsapp Report Send Successfully", 'Msg');
    });
    }
    Send_Referer_Email()
    {
      this.appservice.get("Api/Invoice/Send_Email_Report?Customer_Name="+this.appservice.Share_Data.ledger_Name +"&Tomail="+this.appservice.Share_Data.Contact_Email+"&Email_Subject="+this.appservice.Email_Subject+"&Body="+this.appservice.Report_Body+"&Contact_No="+this.appservice.Share_Data.Phone_Number+"&Bill_No="+this.appservice.Share_Data.Bill_No+"&Bill_Date="+this.appservice.Share_Data.Bill_Date).subscribe((res: any) => {
      this.toastr.success("Email Send Successfully", 'Msg');
    });
  
    }
    Send_Patient_Email()
    {
      this.btndisable1=true;
      this.appservice.get("Api/Invoice/Send_Email_Report?Customer_Name="+this.appservice.Share_Data.ledger_Name +"&Tomail="+this.appservice.Share_Data.Email_ID+"&Email_Subject="+this.appservice.Email_Subject+"&Body="+this.appservice.Report_Body+"&Contact_No="+this.appservice.Share_Data.Phone_Number+"&Bill_No="+this.appservice.Share_Data.Bill_No+"&Bill_Date="+this.appservice.Share_Data.Bill_Date).subscribe((res: any) => {
      this.toastr.success("Email Send Successfully", 'Msg');
      this.btndisable1=false;

    });
  
    }
    Send_PO_Email()
    {
      this.btndisable1 = true;

      this.appservice.get("Api/Invoice/Send_Email_PO_Report?Customer_Name="+this.appservice.Share_Data.ledger_Name +"&Tomail="+this.appservice.Share_Data.Email_ID+"&Email_Subject="+this.appservice.Email_Subject+"&Body="+this.appservice.Report_Body+"&Contact_No="+this.appservice.Share_Data.Phone_Number+"&PO_No="+this.appservice.Share_Data.PO_No+"&PO_Date="+this.appservice.Share_Data.PO_Date).subscribe((res: any) => {
      this.toastr.success("Email Send Successfully", 'Msg');
      this.btndisable1 = false;

    });
  
    }
  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-with-image',
  templateUrl: './register-with-image.component.html',
  styleUrls: ['./register-with-image.component.scss']
})
export class RegisterWithImageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

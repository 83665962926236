<div class="col-sm-12 form-group nopad" style="text-align: center;" >
  <div>
      <br>
      <webcam [height]="200" [width]="200" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
      [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
      [videoOptions]="videoOptions"
      [imageQuality]="1"
      (cameraSwitched)="cameraWasSwitched($event)"
      (initError)="handleInitError($event)"
></webcam>


<br/>
<button class=" btn btn-info btn-sm actionBtn1"   (click)="triggerSnapshot();">Take Visitor Photo</button>

<br>
<br>
<input type='file'  value="Browse image" (change)="onSelectFile($event)"> 
   


</div>






  
</div>
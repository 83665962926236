<!-- Start Footer End -->
<footer class="footer-area">
    <div class="row align-items-center">
        <div class="col-lg-6 col-sm-6 col-md-6">
            <p>Copyright <i class='bx bx-copyright'></i> 2020 <a routerLink="/dashboard/analytics">Fiva</a>. All rights reserved</p>
        </div>

        <div class="col-lg-6 col-sm-6 col-md-6 text-right">
            <p>Designed by ❤️ <a routerLink="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
        </div>
    </div>
</footer>
<!-- End Footer End -->

<div class="chartjs-colors"> <!-- To use template colors with Javascript -->
    <div class="bg-primary"></div>
    <div class="bg-primary-light"></div>
    <div class="bg-secondary"></div>
    <div class="bg-info"></div>
    <div class="bg-success"></div>
    <div class="bg-success-light"></div>
    <div class="bg-danger"></div>
    <div class="bg-warning"></div>
    <div class="bg-purple"></div>
    <div class="bg-pink"></div>
</div>
<div class="row">
    <div class="col-md-12 nopad">
        <div class="card content ">

            <div class=" Head ">



                <div class="card-header  d-flex justify-content-between align-items-center">
                    <h3>Search Instruments</h3>

                    <div class="dropdown">


                        
                        <a (click)="appservice.back()" class="x">
                            <b>X</b>
                        </a>


                    </div>
                </div>

            </div>

            <div class="content_body">

<div class="col-md-12" style="text-align: center; margin-bottom: 30px;">
                <table  style="width: 100%;">
                   

                        <tr >
                           
                            <td style="text-align: center;">
                                Instrument Name
                                <app-vinledger1  class="form-control Name " 
                                [(inputModel)]="add.Master_INS_Code"
                                [(label_)]="add.Master_INS_Code"
                                [(values_)]="add.Master_INS_Code"
                                [Row]="appservice.Instrument_Details_Rows"
                                (valueModelChange)="add.Master_INS_Code= $event"
                                (inputModelChange)="add.Master_INS_Code= $event"
                                (focusout)="get_Master_ID()"
                                (change)="get_Master_ID()"
                                [(ngModel)]="add.Master_INS_Code"
                                name="Master_INS_Code" #Master_INS_Code="ngModel">
                            </app-vinledger1>
                              



                               
                                
                            </td>
                        </tr>
                    <tr>

                            <td style="width: 100px; vertical-align: bottom; "><button  (click)="get_Master_ID()" class="btn btn-success rounded-pill">Get Data</button> </td>
                        </tr>

                      
                </table>
            </div>

                <table *ngIf="disp_data==true" class="table table-bordered">
                    <tbody>
                        <tr>
                            <th style="max-width: 150px;">Instrument ID</th>
                            <td> {{add.INS_ID}} </td>
                            <th>Make</th>
                            <td> {{add.Make}}</td>
                        </tr>
                        <tr>
                            <th style="max-width: 150px;">Instrument Type</th>
                            <td>{{add.INS_Type}}</td>

                            <th>Serail No</th>
                            <td> {{add.MFC_SL_No}}</td>

                        </tr>
                        <tr>
                            <th style="max-width: 150px;">Instrument Name</th>
                            <td>{{add.INS_Name}}</td>
                            <th>Location </th>
                            <td>{{add.Location}}</td>

                        </tr>
                        <tr>
                            <th style="max-width: 150px;">Range</th>
                            <td>{{add.Range}}</td>
                        </tr>


                        <tr>
                            <th style="max-width: 150px;">Least Count</th>
                            <td>{{add.Least_Count}}</td>

                           
                            <th colspan="2"><button (click)="load_instrument(add)" class="btn btn-primary rounded-pill"> Instrument Details</button> &nbsp; <button (click)="load_Cal_Entry(add)" class="btn btn-danger rounded-pill"> Calibration Entry</button></th>
                        </tr>








                    </tbody>
                </table>

              
            </div>
        </div>
    </div>
</div>
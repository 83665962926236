import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-forgot-new-password',
  templateUrl: './forgot-new-password.component.html',
  styleUrls: ['./forgot-new-password.component.scss']
})
export class ForgotNewPasswordComponent implements OnInit {

  isload:boolean=false;
  data :any={}
  constructor(public http: HttpClient,public appservice: AppService,private toastr: ToastrService, public router: Router) {
 
  }

get_Password()
{
  return this.router.navigate(['/forgot-new-password']);
 
}
get(ID)
{
  alert(ID)
}
get_Login()
{
  return this.router.navigate(['authentication/login'])

}
get_Signup()
{
  return this.router.navigate(['/sign-up'])
}

  public btndisable:boolean=false;

  public add: any = {};
  rows = [];
  public Email_ID="";

  login() {

    this.btndisable=true;

    this.appservice.get("Api/Mail/Send_Password?Tomail=" + this.Email_ID ).subscribe((res: any) => {
    this.btndisable=false;
      if (res=="true")
       {
        this.error_Msg="";
        this.error_=" Email ID should not empty";
       }
      else
      if(res=="false")
    {
      this.error_Msg="";
      this.error_="Invalid Email ID";

    }
    else
    {
      this.error_="";
      this.error_Msg="Mail Send Successfully, Please check your Email ID.";
    }

    });
  
  }
  error_="";
  error_Msg="";
  ngOnInit() {
  }

}


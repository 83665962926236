
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';



@Component({
  selector: 'app-item-master',
  templateUrl: './item-master.component.html',
  styleUrls: ['./item-master.component.scss']
})
export class ItemMasterComponent implements OnInit {


  ngOnInit() {
  }
  Rows = [];
  cols: any[];

  btndisable: boolean = false;
  filter: boolean = false;
  headers;
  data;
add:any={};
public access_tocken = "";

  

  fil() {
    if (this.filter) {
      this.filter = false;
    }
    else {
      this.filter = true;
    }
  }
  constructor(public confirmationService: ConfirmationService, private toastr: ToastrService, public appservice: AppService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {
   
  }


  addReset() {
    this.appservice.isadd = "0";
    
    return this.router.navigate(['/add-items']);
  }

  onEdit(item) {
    this.appservice.isadd = "1";
    this.appservice.Edit_Row = item;
    return this.router.navigate([this.appservice.add_item_name]);
  }

  onDelete(item) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete press Yes?',
      accept: () => {
        this.Delete(item)
      }
    });
  }


  Delete(item) {
    this.appservice.get("Api/master/delete_Item_Master?ID=" + item).subscribe((res: any) => {
      var index = this.appservice.Item_Master_Rows.findIndex(function (items, i) {
        return items.ID === item
      });
      if (index > -1) {
        this.appservice.Item_Master_Rows.splice(index, 1);
      }
      this.appservice.Item_Master_Rows = this.appservice.Item_Master_Rows;
      this.appservice.get_Item_Master();
    });
  }





  export_excel(data)
  {
 
   this.appservice.Excel_Data.Header=this.appservice.Item_Master_Export;
   this.appservice.Excel_Data.items=this.appservice.Item_Master_Rows;
   this.appservice.Excel_Data.Report_Name="Item details"
   this.appservice.export_excel();
 
  }


  export_pdf(data)
 {

  this.appservice.Excel_Data.Header=this.appservice.Item_Master_Export;
  this.appservice.Excel_Data.items=this.appservice.Item_Master_Rows;
  this.appservice.Excel_Data.Report_Name="Item details"
  this.appservice.export_pdf();
  }


}
